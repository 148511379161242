const config = {
  s3: {
    REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
    BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://kdhvpgz10m.execute-api.us-east-1.amazonaws.com/stg",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_AoxJpKAHR",
    APP_CLIENT_ID: "3evpkj21jnrrmsar4kh53frh25",
    IDENTITY_POOL_ID: "us-east-1:edaf6778-2313-4965-9c13-77ddcc3298ce",
  },
};

export default config;
